import { render, staticRenderFns } from "./LqfTokens.vue?vue&type=template&id=462751be&scoped=true&"
import script from "./LqfTokens.vue?vue&type=script&lang=js&"
export * from "./LqfTokens.vue?vue&type=script&lang=js&"
import style0 from "./LqfTokens.vue?vue&type=style&index=0&id=462751be&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "462751be",
  null
  
)

export default component.exports