<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <div class="h4">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" class="mt-2 go-back"
             xmlns="http://www.w3.org/2000/svg"
             v-if="template.currentAction !== 'CLAIM_LQF_TOKENS'"
             @click="$router.push('/governance')"
             clickable>
          <rect width="1.83333" height="12.8333" rx="0.916667" transform="matrix(0 -1 -1 0 17.4165 11.9165)"
                fill="#1BC5BD"/>
          <path
              d="M11.6483 15.8517C12.0063 16.2096 12.0063 16.79 11.6483 17.148C11.2904 17.506 10.71 17.506 10.352 17.148L4.85198 11.648C4.50495 11.301 4.49281 10.7422 4.82444 10.3804L9.86611 4.88042C10.2082 4.50723 10.7881 4.48202 11.1612 4.82411C11.5344 5.1662 11.5596 5.74606 11.2176 6.11925L6.76896 10.9723L11.6483 15.8517Z"
              fill="#1BC5BD"/>
        </svg>
        {{ getActionTemplate.title }}
        <template v-if="stepperUuid && !isStepExists(stepperUuid)">
          <div class="liquidity-flow-button" v-if="template.currentAction === 'CLAIM_LQF_TOKENS'">
            <LiquidityFlow
                :time1="$store.state.governance.first_lqf_calculate_date"
                :time2="$store.state.governance.next_lqf_claim_date"
                :currentStep="$store.state.governance.lqf_minting_step"
                popover
            ></LiquidityFlow>
          </div>
        </template>
      </div>
      <template v-if="stepperUuid && !isStepExists(stepperUuid)">
        <b-tabs class="mt-3 mx-n5" align="center"
                v-if="template.currentAction !== 'CLAIM_LQF_TOKENS'"
                v-model="selectedPoolTab"
                lazy>
          <b-tab title="Deposit"
                 :active=showDeposit></b-tab>
          <b-tab title="Remove"
                 :active=showRemove></b-tab>
        </b-tabs>
      </template>
    </template>
    <template v-if="!isStepExists(stepperUuid)">
      <template v-if="showDeposit">
        <DepositPool
            :id="'deposit-'+template.currentAction"
            :ref="'deposit-'+template.currentAction"
            :chosenPool="chosenPool"
            @valid="updateValid"
        ></DepositPool>
      </template>
      <template v-else-if="showRemove">
        <RemovePool
            :id="'remove-'+template.currentAction"
            :ref="'remove-'+template.currentAction"
            :chosenPool="chosenPool"
            @valid="updateValid"
        ></RemovePool>
      </template>
      <template v-else>
        <div class="b-card-content">
          <b-row class="px-5">
            <b-col md="auto" class="mt-3">
              <TextBlock label="LQF balance"
                         tooltip="Your amount of tokens produced by liquifi.org and used for governance and project management"
                         :amount="mathRound($store.state.governance.lqf_balance).toString()"
                         :amountTooltip="bNValue($store.state.governance.lqf_balance)"
              ></TextBlock>
            </b-col>
            <b-col class="mt-3">
              <TextBlock label="Unclaimed LQF tokens"
                         tooltip="Number of tokens you can claim now. You have to claim tokens every week to maximize your income"
                         :amount="mathRound($store.state.governance.unclaimed_lqf_tokens).toString()"
                         :amountTooltip="bNValue($store.state.governance.unclaimed_lqf_tokens)"
              ></TextBlock>
            </b-col>
          </b-row>
          <b-row class="px-5">
            <b-col>
              <LiquidityFlow
                  :time1="$store.state.governance.first_lqf_calculate_date"
                  :time2="$store.state.governance.next_lqf_claim_date"
                  :currentStep="$store.state.governance.lqf_minting_step"
              ></LiquidityFlow>
            </b-col>

          </b-row>
        </div>
      </template>
      <div class="card-footer">

        <Countdown :time="getDateToTimer" v-if="showTimer"></Countdown>

        <b-button block variant="primary" class="button-flat mt-3"
                  v-if="!showTimer && !hideButton"
                  :disabled="!isFormValid"
                  v-on:click="openConfirmationPopup">
          <template>{{ getBtnText() }}</template>
        </b-button>

      </div>
      <div class="card-table mb-3" v-if="template.currentAction === 'CLAIM_LQF_TOKENS'">
        <div class="row align-items-center justify-content-between">
          <div class="col card-table-title col-md-auto">Your liquidity pools</div>
          <div class="col card-table-link col-auto" v-on:click="goToDashboard()">All pools</div>
        </div>
        <template v-if="!isMobile">
          <b-table borderless
                   :fields="existingPoolsFields"
                   :items="$store.state.governance.pools"
                   :tbody-tr-class="getRowClass"
                   v-if="$store.state.web3.networkId && $store.state.governance.pools.length > 0"
                   @row-clicked="rowClickHandler">
            <template v-slot:cell(pool)="{item}">
              <TokenPair :tFrom=item.pair[0]
                         :tTo=item.pair[1]
              ></TokenPair>
            </template>
            <template v-slot:cell(poolTokensAvailable)="{item}">
          <span v-b-tooltip.hover v-bind:title="item.pool_tokens_available" v-b-tooltip="{customClass: 'dark-tooltip'}"
                clickable>
              {{ mathRound(item.pool_tokens_available) }}
          </span>
            </template>
            <template v-slot:cell(poolTokensDeposited)="{item}">
          <span v-b-tooltip.hover v-bind:title="item.pool_tokens_deposited" v-b-tooltip="{customClass: 'dark-tooltip'}"
                clickable>
              {{ mathRound(item.pool_tokens_deposited) }}
          </span>
            </template>
          </b-table>
        </template>
        <template v-if="isMobile">
          <div class="pool mx-5 mb-3" v-for="(item, index) in $store.state.governance.pools" :key="'pool'+index">
            <div class="pool-header">
              <div>
                <TokenPair :tFrom="item.pair[0]" :tTo="item.pair[1]" btnView></TokenPair>
              </div>
            </div>
            <div class="pool-body">
              <div class="pool-row">
                <div class="caption">Pool tokens available:</div>
                <div v-b-tooltip.hover v-bind:title="item.pool_tokens_available"
                     v-b-tooltip="{customClass: 'dark-tooltip'}"
                     clickable>
                  {{ mathRound(item.pool_tokens_available) }}
                </div>
              </div>
              <div class="pool-row">
                <div class="caption">Pool tokens deposited:</div>
                <div v-b-tooltip.hover v-bind:title="item.pool_tokens_deposited"
                     v-b-tooltip="{customClass: 'dark-tooltip'}"
                     clickable>
                  {{ mathRound(item.pool_tokens_deposited) }}
                </div>
              </div>
            </div>

          </div>

        </template>
        <b-row class="px-5 py-3" v-if="$store.state.governance.pools.length === 0">
          <b-col>
            <div>
              <div class="caption info">
                Pool tokens required
              </div>
              <div>
                Once you added liquidity to one of the liquidity pools you can deposit pool tokens on this page. <br>
                <span v-on:click="$router.push('/liquidity')" class="simple-link">Switch to add liquidity</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-else-if="isStepExists(stepperUuid)">
      <Step :con-data="confirmationPopupData" @close="onCloseStepper"></Step>
      <StepInformer :steps="getStepsById(stepperUuid)" :stepperUuid="stepperUuid"></StepInformer>
    </template>

    <ConfirmationGovernanceModal :ref="'confirmation-governance-modal'"
                                 :id="'confirmation-governance-modal'"
                                 :con-data="confirmationPopupData"
                                 @ok="onConfirmOk"
    ></ConfirmationGovernanceModal>
  </b-card>

</template>

<script>

import {
  CLAIM_LQF_TOKEN,
  DEPOSIT_LQF_TOKENS,
  HTTP_GET_LQF_DATA,
  WITHDRAW_LQF_TOKENS
} from "@/store/modules/governance.module";
import {mapActions, mapGetters} from "vuex";
import {mathRound} from "@/helpers/utils";
import BigNumber from "bignumber.js";
import {uuid} from "vue-uuid";
import {DELETE_STEP_BY_ID, INIT_STEP} from "@/store/modules/stepper.module";

export const CLAIM_LQF_TOKENS = 'CLAIM_LQF_TOKENS'
export const DEPOSIT_POOL = 'DEPOSIT_POOL'
export const REMOVE_POOL = 'REMOVE_POOL'

export default {
  name: "LqfTokens",
  components: {
    DepositPool: () => import("@/view/pages/governance/DepositPool"),
    RemovePool: () => import("@/view/pages/governance/RemovePool"),
    TokenPair: () => import("@/components/form/TokenPair"),
    TextBlock: () => import("@/components/form/TextBlock"),
    ConfirmationGovernanceModal: () => import("@/components/confirmation/governance/ConfirmationGovernanceModal"),
    LiquidityFlow: () => import("@/components/governance/LiquidityFlow"),
    Countdown: () => import("@/components/governance/Countdown"),
    Step: () => import("@/components/stepper/Step"),
    StepInformer: () => import("@/components/stepper/StepInformer"),
  },
  data: function () {
    return {
      template: {
        currentAction: CLAIM_LQF_TOKENS,
        actions: {
          CLAIM_LQF_TOKENS: {
            title: 'LQF tokens',
            btnText: 'Claim LQF tokens'
          },
          DEPOSIT_POOL: {
            title: '',
            btnText: 'Deposit'
          },
          REMOVE_POOL: {
            title: '',
            btnText: 'Remove'
          }
        },
        isValid: false,
      },
      chosenPool: null,
      selectedPoolTab: 0,
      confirmationPopupData: {},
      existingPoolsFields: [
        {key: 'pool', thClass: 'caption'},
        {key: 'poolTokensAvailable', thClass: 'caption'},
        {key: 'poolTokensDeposited', thClass: 'caption'},
      ],
      stepperUuid: uuid.v1(),
    }
  },
  watch: {
    '$route': {
      handler: async function (route) {
        this.updateValid(false)
        await this.httpGetLqfData()
        if (route.name === 'lqf-tokens') {
          this.setTemplate(CLAIM_LQF_TOKENS)
        }
        if (route.name === 'deposit-pool') {
          this.chosenPool = null
          if (Object.keys(route.query).length > 0) {
            const chPool = this.getPoolByTokenPair([route.query.f, route.query.t])
            if (!chPool) {
              await this.$router.push('/governance/lqf-tokens')
            }
            this.chosenPool = chPool
          }
          this.setTemplate(DEPOSIT_POOL)
        }
      },
      immediate: true
    },
    '$store.state.web3.networkId': {
      handler: async function (val) {
        if (val) {
          await this.httpGetLqfData()
        }
      },
    },
    selectedPoolTab(val) {
      if (val === 1) {
        this.setTemplate(REMOVE_POOL)
      } else {
        this.setTemplate(DEPOSIT_POOL)
      }
    }
  },
  methods: {
    setTemplate(name) {
      switch (name) {
        case CLAIM_LQF_TOKENS:
          this.template.currentAction = CLAIM_LQF_TOKENS
          break
        case DEPOSIT_POOL:
          this.template.currentAction = DEPOSIT_POOL
          this.template.actions[this.template.currentAction].title = `
                            ${this.$route.query.f}/${this.$route.query.t} pool     `
          break
        case REMOVE_POOL:
          this.template.currentAction = REMOVE_POOL
          this.template.actions[this.template.currentAction].title = `
                            ${this.$route.query.f}/${this.$route.query.t} pool     `
          break
      }
    },
    openConfirmationPopup() {
      this.confirmationPopupData = this.getConfirmationData()
      if (this.confirmationPopupData) {
        this.$refs['confirmation-governance-modal'].$refs['confirmation-governance-modal'].show()
      }
    },
    getConfirmationData() {
      let confirmationData = {
        title: '', modalType: 'confirm', waiting: false, pool: {}, listData: [],
      }
      let confirmData = {}
      switch (this.template.currentAction) {
        case CLAIM_LQF_TOKENS:
          confirmationData.title = 'Claim LQF Tokens'
          confirmationData.pool = {
            from: {
              title: '', name: 'LQF',
              value: mathRound(this.$store.state.governance.unclaimed_lqf_tokens, 8)
            },
          }
          break
        case DEPOSIT_POOL:
          confirmationData.title = 'Confirm deposit'
          confirmData = this.$refs['deposit-' + this.template.currentAction].confirmData
          confirmationData.pool = {
            from: {
              title: '', name: confirmData.pool.symbol,
              value: mathRound(confirmData.pool.amount, 8)
            },
          }
          confirmationData.listData = confirmData.listData
          break
        case REMOVE_POOL:
          confirmationData.title = 'Confirm remove'
          confirmData = this.$refs['remove-' + this.template.currentAction].confirmData
          confirmationData.pool = {
            from: {
              title: '', name: confirmData.pool.symbol,
              value: mathRound(confirmData.pool.amount, 8)
            },
          }
          confirmationData.listData = confirmData.listData
      }
      return confirmationData
    },
    assignConfirmationData(data) {
      this.confirmationPopupData = Object.assign(this.confirmationPopupData, data)
    },
    async onCloseStepper() {
      this.deleteStepById(this.stepperUuid)
      await this.httpGetLqfData()
      this.stepperUuid = uuid.v1()
    },
    async onConfirmOk(op) {
      if (op) {
        this.initStep(this.stepperUuid)
        this.$refs['confirmation-governance-modal'].$refs['confirmation-governance-modal'].hide()
        let confirmData = {}
        this.assignConfirmationData({waiting: true, modalType: 'confirm'})
        switch (this.template.currentAction) {
          case CLAIM_LQF_TOKENS:
            await this.claimLqfToken({stepUuid: this.stepperUuid}).then(async (tx) => {
              this.assignConfirmationData({
                etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
                successMessage: 'Success!',
                waiting: false,
                modalType: 'success'
              })
              await this.httpGetLqfData()
            }).catch((e) => {
              this.assignConfirmationData(
                  {modalType: 'error', errorMessage: e.message, waiting: false})
            })
            break
          case DEPOSIT_POOL:
            //TODO add deposit lqf
            //TODO if needed live data update - use after transaction success:
            //await this.httpGetLqfData()
            // this.chosenPool = this.getPoolByTokenPair([route.query.f, route.query.t])
            confirmData = this.$refs['deposit-' + this.template.currentAction].confirmData
            await this.depositLqfTokens({
              pool: this.chosenPool,
              symbol: confirmData.pool.symbol,
              amount: confirmData.pool.amount,
              stepUuid: this.stepperUuid
            }).then(async (tx) => {
              this.assignConfirmationData({
                etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
                successMessage: 'Success!',
                waiting: false,
                modalType: 'success'
              })
              await this.httpGetLqfData()
            }).catch((e) => {
              this.assignConfirmationData(
                  {modalType: 'error', errorMessage: e.message, waiting: false})
            })
            break
          case REMOVE_POOL:
            //TODO add remove lqf
            //TODO if needed live data update - use after transaction success:
            //await this.httpGetLqfData()
            // this.chosenPool = this.getPoolByTokenPair([route.query.f, route.query.t])
            confirmData = this.$refs['remove-' + this.template.currentAction].confirmData
            await this.withdrawLqfTokens({
              pool: this.chosenPool,
              amount: confirmData.pool.amount,
              stepUuid: this.stepperUuid
            }).then(async (tx) => {
              this.assignConfirmationData({
                etherscanURL: this.getEtherscanTxLink(tx.transactionHash),
                successMessage: 'Success!',
                waiting: false,
                modalType: 'success'
              })
              await this.httpGetLqfData()
            }).catch((e) => {
              this.assignConfirmationData(
                  {modalType: 'error', errorMessage: e.message, waiting: false})
            })
            break
        }
      }
    },
    rowClickHandler(record) {
      if (record.pair.find(el => this.checkBaseToken(el))) {
        this.$router.push({name: 'deposit-pool', query: {f: record.pair[0], t: record.pair[1]}})
      }
    },
    updateValid(result) {
      this.template.isValid = result;
    },
    mathRound(number, decimalPlaces = 6) {
      return mathRound(number, decimalPlaces)
    },
    bNValue(value) {
      return new BigNumber(value).valueOf()
    },
    goToDashboard() {
      return this.$router.push('/dashboard/arbitrage')
    },
    getRowClass(item, type) {
      if (item && type === 'row') {
        return item.pair.find(el => this.checkBaseToken(el)) ? 'hover-link' : 'row-muted';
      }
    },
    getBtnText() {
      if (this.$store.state.governance.lqf_minting_step === 4 && this.template.currentAction === CLAIM_LQF_TOKENS) {
        return 'Calculate LQF share';
      } else {
        return this.template.actions[this.template.currentAction].btnText;
      }
    },

    ...mapActions([
      HTTP_GET_LQF_DATA,
      CLAIM_LQF_TOKEN,
      DEPOSIT_LQF_TOKENS,
      WITHDRAW_LQF_TOKENS,
      INIT_STEP,
      DELETE_STEP_BY_ID
    ])
  },
  computed: {
    getActionTemplate() {
      return this.template.actions[this.template.currentAction]
    },
    showDeposit() {
      return this.template.currentAction === DEPOSIT_POOL
    },
    showRemove() {
      return this.template.currentAction === REMOVE_POOL
    },
    isFormValid() {
      switch (this.template.currentAction) {
        case CLAIM_LQF_TOKENS:
          return true
        default:
          return this.template.isValid
      }
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
    showTimer() {
      return [3, 5].includes(this.$store.state.governance.lqf_minting_step) &&
          this.template.currentAction === CLAIM_LQF_TOKENS
    },
    hideButton() {
      return [0, 1, 2].includes(this.$store.state.governance.lqf_minting_step) &&
          this.template.currentAction === CLAIM_LQF_TOKENS
    },
    getDateToTimer() {
      return this.$store.state.governance.lqf_minting_step === 3
          ? this.$store.state.governance.first_lqf_calculate_date
          : this.$store.state.governance.next_lqf_claim_date
    },
    ...mapGetters([
      'getPoolByTokenPair',
      'getEtherscanTxLink',
      'isStepExists',
      'getStepsById',
      'checkBaseToken'
    ])
  },

}
</script>

<style scoped lang="scss">
  .go-back {
    position: absolute;
    left: 2rem;
    color: $color-primary;
  }

  .liquidity-flow-button {
    position: absolute;
    right: 3rem;
    top: 12px;
  }
</style>
